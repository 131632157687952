import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import { StaticImage } from 'gatsby-plugin-image'
// import { Link } from 'gatsby'
import DigitalSecurityIcon from '../assets/About/Icons/digital_security.svg'
import IdentityIcon from '../assets/About/Icons/identity.svg'
import SolutionsIcon from '../assets/About/Icons/solutions.svg'
import ConventionsIcon from '../assets/About/Icons/conventions.svg'
// import DiamondBG from '../assets/About/Images/diamond_bg.png'

import CTABox from '../components/CTABox'
import Section from '../components/Section'
import SectionTitleBlock from '../components/SectionTitleBlock'
import Divider from '../components/Divider'
import CTALink from '../components/CTALink'
import routes from '../routes'
import ContactUs from '../components/ContactUs'

const HeroImage = '../assets/About/Images/about_header.png'
const MissionImg = '../assets/About/Images/mission.png'
// const PDF = '../assets/About/Icons/pdfFileImage.svg'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ========================= */
  link: {
    textDecoration: 'underline',
    color: '#4377bb',
    fontWeight: 500,
  },
  sectionTitle: {
    textAlign: 'center',

    marginBottom: 15,
    maxWidth: 280,

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    textAlign: 'center',

    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },
  thinDivider: {
    width: 75,
    borderBottom: '1px solid rgba(47, 164, 59, 1)',

    [theme.breakpoints.up('tablet')]: {
      position: 'relative',
      top: 20,
      width: 0,
      height: 60,

      borderBottom: 'none',
      borderLeft: '1px solid rgba(47, 164, 59, 1)',
    },

    [theme.breakpoints.up('desktop')]: {
      position: 'relative',
      top: 20,
      height: 75,
    },
  },

  /**
   * || Hero Section
   * ======================================= */
  hero: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    height: 530,
    top: 0, // Account for the header bar height
    paddingTop: 48,
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,

    [theme.breakpoints.up('tablet')]: {
      marginBottom: 50,
      flexDirection: 'row-reverse',
    },

    [theme.breakpoints.up('desktop')]: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 680,
      marginBottom: 10,
    },
  },

  heroImage: {
    display: 'none',

    [theme.breakpoints.up('tablet')]: {
      display: 'block',
      position: 'relative !important',

      width: 'calc(220px + 10%)',
      right: 20,
      top: 30,

      filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.51))',
      borderRadius: 5,
    },

    [theme.breakpoints.up('700')]: {
      marginRight: 30,
    },

    [theme.breakpoints.up('800')]: {
      marginRight: '2%',
    },

    [theme.breakpoints.up('920')]: {
      width: 'calc(220px + 10%)',
      marginRight: '5%',
    },

    [theme.breakpoints.up('920')]: {
      width: 'calc(240px + 10%)',
      marginRight: '5%',
    },

    [theme.breakpoints.up('desktop')]: {
      width: 420,
      height: 475,
      right: 0,
      marginRight: 0,
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    marginTop: 70,
    maxWidth: 285,
    width: '90%',

    [theme.breakpoints.up('800')]: {
      maxWidth: 417,
    },

    [theme.breakpoints.up('920')]: {
      marginRight: 30,
    },

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',
      marginLeft: 70,
      maxWidth: 590,
    },
    [theme.breakpoints.up('desktopXL')]: {
      marginLeft: 100,
    },
  },
  heroTitle: {
    color: 'black',
    [theme.breakpoints.up('desktop')]: {
      fontSize: 25,
    },
  },
  heroDescription: {
    textAlign: 'left',
    color: 'rgba(115, 115, 115, 1)',
    marginTop: '15px',
    marginBottom: '15px',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {},

    [theme.breakpoints.up('desktop')]: {
      fontSize: 14,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 10,

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },

  signUpButton: {
    width: '90%',
    marginBottom: 10,
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      marginRight: 10,
      fontSize: '12px',

      padding: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '110px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      width: '170px',
    },
  },

  /**
   * || Quote section
   * ======================================= */
  quoteWrapper: {
    display: 'none',
    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      marginTop: 100,
      width: '100%',
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 150,
    },
  },
  quoteFont: {
    fontWeight: 600,
    fontSize: 14,
    width: 480,

    [theme.breakpoints.up('910')]: {
      textAlign: 'center',
      fontSize: 18,
      width: 870,
    },

    [theme.breakpoints.up('1300')]: {
      fontSize: '25px',
      width: 1100,
    },
  },
  quotationMark1: {
    position: 'absolute',
    color: 'rgba(47, 164, 59, 1)',
    fontSize: 50,
    top: -15,
    left: -1,

    [theme.breakpoints.up('910')]: {
      top: -15,
      left: 16,
    },

    [theme.breakpoints.up('1300')]: {
      fontSize: 96,
      top: -40,
      left: -35,
    },
  },
  founders: {
    padding: 20,
    fontSize: 12,
    color: '#737373',

    [theme.breakpoints.up('1300')]: {
      fontSize: 16,
    },
  },
  quotationMark2: {
    position: 'absolute',
    color: 'rgba(47, 164, 59, 1)',
    fontSize: 50,
    top: 50,
    right: 118,

    // transform: 'rotate(1deg)',
    [theme.breakpoints.up('910')]: {
      top: 40,
      right: 265,
    },
    [theme.breakpoints.up('1300')]: {
      fontSize: 96,
      top: 54,
      left: 825,
    },
  },
  quoteAndParagraphContainer: {
    position: 'relative',
  },
  /**
   * || Who We Are Section
   * ======================================= */
  whoSection: {
    marginTop: 50,

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      justifyContent: 'center',

      marginTop: 125,
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 130,
    },

    [theme.breakpoints.up('desktopXL')]: {},
  },
  whoColumnLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginBottom: 50,

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',
    },

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',
      maxWidth: 420,
      marginRight: 50,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginRight: 100,
    },
  },
  philosophiesCard: {
    padding: 50,

    background: 'white',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    textAlign: 'center',
  },
  philosophiesGrid: {
    display: 'grid',

    marginTop: 50,
    rowGap: 40,

    '& h3': {
      gridColumn: 'span 2',
    },

    [theme.breakpoints.up('tablet')]: {
      gridTemplate: '1fr 1fr /1fr 1fr',

      columnGap: 45,
    },
  },
  philosophiesGridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: 250,

    textAlign: 'center',

    '& svg': {
      // width: 'auto',
      // height: 35,

      color: '#2fa43b',
    },
  },

  /**
   * || Our Mission Section
   * ======================================= */
  missionSection: {
    marginTop: 80,
    paddingTop: 50,
    paddingBottom: 50,

    [theme.breakpoints.up('tablet')]: {
      marginTop: 80,
      paddingTop: 100,
      paddingBottom: 100,
    },
    // backgroundImage: `url(${DiamondBG})`,
    // backgroundSize: 'cover',
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    [theme.breakpoints.up('desktopXL')]: {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  missionImage: {
    width: '100%',
    maxWidth: 420,
    marginBottom: 50,

    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,

    [theme.breakpoints.up('desktop')]: {
      marginBottom: 0,
      marginRight: 50,
      maxWidth: 520,
    },
    [theme.breakpoints.up('desktopXL')]: {
      marginRight: 100,
      maxWidth: 620,
    },
  },
  missionTitleBlock: {
    padding: 45,
    maxWidth: 460,

    backgroundColor: 'white',
    border: '1px solid #E7E7E7',
    borderRadius: 10,

    '& h2': {
      textAlign: 'left',
    },

    '& p': {
      textAlign: 'left',
    },
  },
  /**
   * || Corporate Details and Contact Information Section
   * ======================================= */
  contactSection: {
    marginTop: 50,
    marginBottom: 100,
    padding: 30,
    paddingTop: 50,
    paddingBottom: 75,

    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    textAlign: 'center',

    [theme.breakpoints.up('desktop')]: {
      marginTop: 100,
      marginBottom: 150,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 120,
    },

    '& h3': {
      marginTop: 25,
      width: '100%',
      maxWidth: 550,

      [theme.breakpoints.up('desktop')]: {
        maxWidth: 800,
      },
    },
  },
  contactFlexBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 25,

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
    },

    [theme.breakpoints.up('desktop')]: {
      width: '100%',
      maxWidth: 1150,
      marginTop: 50,
      margin: 'auto',
    },
  },
  contactText: {
    fontSize: 12,
    color: 'white',
    '& small': { fontSize: 12, fontWeight: 300 },

    [theme.breakpoints.up('tablet')]: {
      fontSize: 10,
      '& small': { fontSize: 10, fontWeight: 300 },
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 14,
      '& small': { fontSize: 14, fontWeight: 300 },
    },
  },
  contactItem: {
    maxWidth: 260,
    margin: '30px 0px',
    padding: 10,
    color: 'black',

    [theme.breakpoints.up('tablet')]: {
      margin: 0,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 'unset',
      margin: 0,
      width: 380,
    },

    '& h4': {
      marginBottom: 8,
    },

    '& p': {
      whiteSpace: 'pre-line',
    },
  },

  ctaBox: {
    marginTop: -50,
    marginBottom: -20,

    [theme.breakpoints.up('tablet')]: {
      marginTop: 120,
      marginBottom: 60,
    },

    [theme.breakpoints.up('desktop')]: {
      marginBottom: 120,
    },
  },
}))

const philosophiesItems = [
  {
    icon: (
      <div style={{ width: 57.5, height: 55 }}>
        <DigitalSecurityIcon />
      </div>
    ),
    description:
      'Every person has the right to privacy and digital security. What you do online is your business and no one else’s.',
  },
  {
    icon: (
      <div style={{ width: 40, height: 55, position: 'relative', top: 5 }}>
        <SolutionsIcon />
      </div>
    ),
    description:
      'Designing solutions should be done ethically and conscientiously. Consumer protections should be intrinsic to digital systems.',
  },
  {
    icon: (
      <div style={{ width: 50, height: 55 }}>
        <IdentityIcon />
      </div>
    ),
    description:
      'Your identity belongs to you and no one else. No third-parties should be able to exploit your identity for their sole benefit.',
  },
  {
    icon: (
      <div style={{ width: 36, height: 55, position: 'relative', top: 5 }}>
        <ConventionsIcon />
      </div>
    ),
    description:
      "There's a better way to do things. Just because something is convention doesn't mean we can't improve it.",
  },
]
export default function About() {
  const classes = useStyles()
  return (
    <main>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          <Typography variant='h2' className={classes.heroTitle}>
            “Authentication is the foundation of digital trust, an enabler of
            cybersecurity in the digital economy and of the fourth industrial
            revolution: in short, authentication is a critical enabler of the
            future.”
          </Typography>

          <Typography variant='body1' className={classes.heroDescription}>
            Andrew Shikiar, Executive Director and Chief Marketing Officer, FIDO
            Alliance USA, the World Economic Forum, 2019
          </Typography>
          <div className={classes.buttonContainer}>
            <CTALink
              className={classes.signUpButton}
              href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
              target='_blank'
              rel='noopener'
            >
              Get Started
            </CTALink>
            <CTALink
              className={classes.signUpButton}
              href={routes.CONTACT}
              variant='outline'
            >
              Talk To Sales
            </CTALink>
          </div>
        </div>
        <StaticImage
          className={classes.heroImage}
          src={HeroImage}
          alt='Graphic of typing username and password authentication'
          loading='eager'
          placeholder='blurred'
        />
      </section>
      <Section className={classes.quoteWrapper}>
        <Divider style={{ marginBottom: 15 }} />
        <div className={classes.quoteAndParagraphContainer}>
          <Typography className={classes.quoteFont}>
            <span className={classes.quotationMark1}>&quot;</span>
            We founded CycurID after years of working in the payments,
            cryptocurrencies and risk management sectors and getting firsthand
            knowledge of how costly and ineffective the current landscape is for
            Identity Verification, KYC and anti-money laundering.
          </Typography>
        </div>
        <br />
        <div className={classes.quoteAndParagraphContainer}>
          <Typography className={classes.quoteFont}>
            We saw an opportunity to think differently, do things differently
            and more importantly, better. CycurID was born from the idea that
            the status quo does not work and achieves little more than
            perpetuating the problem.
            <span className={classes.quotationMark2}>&quot;</span>
          </Typography>
        </div>
        <Typography className={classes.founders}>
          Founders -{' '}
          <span style={{ color: 'rgba(47, 164, 59, 1)' }}>
            {' '}
            Mehdi Mehrtash | Gord Jessop
          </span>
        </Typography>
      </Section>
      <Section className={classes.whoSection}>
        <div className={classes.whoColumnLeft}>
          <Divider style={{ marginBottom: 15 }} />
          <SectionTitleBlock
            title='Who We Are'
            description="We don’t accept the status quo, and we're not satisfied with band-aid solutions. We are proactive, actively addressing and resolving the keystone issues facing our digital society."
            textAlignLeft='true'
          />
        </div>
        <div className={classes.philosophiesCard}>
          <Typography variant='h3'>Our Core Philosophies</Typography>
          <div className={classes.philosophiesGrid}>
            {philosophiesItems.map((item) => (
              <div
                className={classes.philosophiesGridItem}
                key={item.description}
              >
                {item.icon}
                <Typography variant='body1'>{item.description}</Typography>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <Section className={classes.missionSection} gutterVariant='padding'>
        <StaticImage
          className={classes.missionImage}
          src={MissionImg}
          alt='Graphic of phone and a matrix of various aspects that are digitally connected through the internet '
          placeholder='blurred'
        />
        <div className={classes.missionTitleBlock}>
          <SectionTitleBlock
            title='Our Mission'
            bar='true'
            description={
              <>
                As our global society becomes more digitized, we’re able to
                close geographic distances like never before. But removing
                physical contact from our private and commercial spaces means
                one critical thing: being able to verify and authenticate your
                identity has become a foundational core need of businesses
                everywhere.{'\n\n'}
                In all aspects of modern life, from using social media, to
                sending and receiving money, to fulfilling public health needs,
                to stopping fraud and crime, your identity is the key to a safe
                and convenient society that works for you and makes your life
                better.
              </>
            }
          />
        </div>
      </Section>
      <Section className={classes.contactSection}>
        <Divider />
        <Typography variant='h3' style={{ fontWeight: 500, color: 'white' }}>
          CycurID Technologies Ltd is a privately held identity management and
          security software developer based in Vancouver, British Columbia. For
          further corporate information or investment opportunities, email us at
          corpinfo@cycurid.com.
          {/* <Link className={classes.link} to={routes.CONTACT}>
            Contact page
          </Link> */}
        </Typography>
        <div className={classes.contactFlexBox}>
          <div className={classes.contactItem}>
            <Typography variant='h4' className={classes.contactText}>
              Canadian Office
            </Typography>
            <Typography variant='body1' className={classes.contactText}>
              CycurID Technologies Ltd{'\n'}{' '}
              <small>
                Suite 600 – 890 West Pender Street,
                {'\n'} Vancouver, BC, V6C 1J8
              </small>
            </Typography>
          </div>
          <div className={classes.thinDivider} />
          <div className={classes.contactItem}>
            <Typography variant='h4' className={classes.contactText}>
              US Office
            </Typography>
            <Typography variant='body1' className={classes.contactText}>
              Cycurid Technologies USA Ltd{'\n'}
              <small>
                539 W. Commerce St. #1959,
                {'\n'}
                Dallas, Tx, 75208
              </small>
            </Typography>
          </div>
          <div className={classes.thinDivider} />
          <div className={classes.contactItem}>
            <Typography variant='h4' className={classes.contactText}>
              Legal
            </Typography>
            <Typography variant='body1' className={classes.contactText}>
              McMillan LLP{'\n'}
              <small>
                Royal Centre, 1055 W Georgia St #1500,{'\n'}
                Vancouver, BC, V6E 4N7
              </small>
            </Typography>
          </div>
          {/* <div className={classes.thinDivider} />
          <div className={classes.contactItem} style={{ maxWidth: 130 }}>
            <Typography variant='h4' className={classes.contactText}>
              Press Kit
            </Typography>
            <StaticImage
              src={PDF}
              alt='PDF File'
              loading='eager'
              placeholder='blurred'
            />
          </div> */}
        </div>
      </Section>
      <CTABox
        title='Our technology will redefine how identity will be used to alter the exchange of goods and services on a global basis.'
        description={
          <>
            CycurID’s technology aims to create a digital marketplace that is
            safer, faster, and more accessible for both businesses and
            consumers.{'\n\n'} Contact us to learn how you can be a part in
            changing the game using Digital Identity.
          </>
        }
        buttons={['Contact Us']}
        className={classes.ctaBox}
        // noShadow={'True'}
        bar='True'
      />
      {/* quick margin fix */}
      <div style={{ height: 70, width: 70 }} />
      <ContactUs />
    </main>
  )
}
